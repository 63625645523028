import React, { useEffect, useState } from "react";
import { useAuth, useForm } from "@hooks";
import { useStyles } from "./InformationCredit.style";
import { DataCredit } from "./DataCredit";

interface informationCreditProps {
  setInformationCreditData: (value: any) => void;
  dataOfSave: (data: any) => void;
}

export const InformationCredit = ({
  setInformationCreditData,
  dataOfSave,
}: informationCreditProps) => {
  const classes = useStyles();
  const [form] = useForm();

  const [credits, setCredits] = useState(form.dataRefinanceCredit?.length | 1);
  useAuth({ redirectIfNotLogged: true });
  const componentFinal: any[] = [];
  const allData = new Array(credits);

  const [isEnabled, setEnabled] = useState(false);
  const [dateNow, setDateNow] = useState<{
    numberCredit?: number;
    category?: string;
    therm?: number;
    amount?: number;
    share?: number;
    date?: Date;
    intereseRate?: number;
  }>();
  const [saveData, setSaveData] = useState<
    {
      numberCredit?: number;
      category?: string;
      therm?: number;
      amount?: number;
      share?: number;
      date?: Date;
      intereseRate?: number;
    }[]
  >([]);
  useEffect(() => {
    isEnabled
      ? setInformationCreditData(true)
      : setInformationCreditData(false);
    isEnabled && dataOfSave(saveData);
  }, [isEnabled, allData]);

  for (let i = 0; i < credits; i++) {
    componentFinal.push(
      <DataCredit
        defaultData={form.dataRefinanceCredit || undefined}
        setCredits={setCredits}
        credits={credits}
        setSaveData={setSaveData}
        number={i + 1}
        saveData={saveData}
        allData={allData}
        enabledButton={isEnabled}
        valueEnabledButton={setEnabled}
        setDateNow={setDateNow}
        setInformationCreditData={setInformationCreditData}
      />
    );
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>1. Información del crédito</div>
        {componentFinal}
      </div>
    </>
  );
};
