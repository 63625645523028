import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import RefinanceCreditStep1 from "@components/RefinanceCredit/Step1/RefinanceCreditStep1";
import { useMediaQuery } from "@material-ui/core";
import { RefinanceLoan2, RefinanceLoan2Mobile } from "@components/Structural";
import useHotjar from "react-use-hotjar";
import { useCurrentUser } from "@apollo";
import { TUserInfo } from "react-use-hotjar/dist/types";
import { useAuth } from "@hooks";

const refinanceCredit = () => {
  useAuth({ redirectIfNotLogged: true });

  const [page, setPage] = useState(1);
  // const [isPage, setPage2] = useState(0);
  const isMobile = useMediaQuery("(max-width: 1300px)");
  const { identifyHotjar } = useHotjar();
  const myCustomLogger = console.log;
  const { user: currentUser } = useCurrentUser();
  const logininfo = {
    name: currentUser?.name,
    identification: process.env.HOTJAR_ID || "0",
  };
  useEffect(() => {
    if (logininfo) {
      const { identification, ...restOfInformation } = {
        name: currentUser?.name,
        identification: process.env.HOTJAR_ID || "0",
      };
      identifyHotjar(
        identification,
        restOfInformation.name as unknown as TUserInfo,
        myCustomLogger
      );
    }
  }, [currentUser]);

  return (
    <LayoutBudget
      title="¿Debería refinanciar mi crédito?"
      onTurnBackClick={() =>
        page === 1 ? navigate(Route.rokipedia+ "/?#tools") : setPage(1)
      }
      subtitle={page === 2 ? "Tu resultado" : ""}
      subtitleMobile=""
    >
      {page === 1 ? (
        <RefinanceCreditStep1 onClick={() => setPage(2)} />
      ) : isMobile ? (
        <>
          {page === 1 ? (
            <RefinanceLoan2Mobile setPage={setPage} />
          ) : (
            <RefinanceLoan2Mobile setPage={setPage} />
          )}
        </>
      ) : (
        <>
          {page === 1 ? (
            <RefinanceLoan2 setPage={setPage} />
          ) : (
            <RefinanceLoan2 setPage={setPage} />
          )}
        </>
      )}
    </LayoutBudget>
  );
};

export default refinanceCredit;
