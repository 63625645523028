import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth, useForm } from "@hooks";
import { useStyles } from "./RefinanceCreditStep1.style";
import { RefinanceStep1Top } from "./TopSection/RefinanceStep1Top";
import { InformationCredit } from "./InformationCredit/InformationCredit";
import { InformationPeople } from "./InformationPeople/InformationPeople";
import { InformationDebt } from "./InformationDebt/InformationDebt";
import { ButtonPrimary } from "@components/Reusables";
import { ProfileContext } from "@context";

interface refinanceProps {
  onClick: () => void;
}

const RefinanceCreditStep1 = ({ onClick }: refinanceProps) => {
  const classes = useStyles();
  const [informationCreditData, setInformationCreditData] = useState(false);
  const [informationLiquidationData, setInformationLiquidationData] =
    useState(false);
  const { urlFile } = useContext(ProfileContext);
  useAuth({ redirectIfNotLogged: true });
  const [form] = useForm({});

  const onClickContinue = useCallback(() => {
    onClick();
  }, []);
  const dataOfSave = (data: any) => {
    form.dataRefinanceCredit = data;
  };
  form.liquidationFile = urlFile?.LIQUIDATION;
  form.debtReport = urlFile?.DEBT;

  return (
    <>
      <div className={classes.container}>
        <RefinanceStep1Top />
        <section>
          <InformationCredit
            dataOfSave={dataOfSave}
            setInformationCreditData={setInformationCreditData}
          />
          <div className="div">
            <InformationPeople
              setInformationCreditData={setInformationLiquidationData}
            />
            <InformationDebt />
          </div>
        </section>
        <div className="button">
          <ButtonPrimary
            text="Siguiente"
            disabled={!informationCreditData || !informationLiquidationData}
            onClick={onClickContinue}
          />
        </div>
      </div>
    </>
  );
};

export default RefinanceCreditStep1;
