import React, { useCallback, useContext, useState } from "react";
import { useAuth, useForm } from "@hooks";
import { useStyles } from "./InformationPeople.style";
import { useMediaQuery } from "@material-ui/core";
import { NumericInput } from "@components/Reusables";
import { ProfileContext } from "@context";
import { useDropzone } from "react-dropzone";
import { ButtonFile } from "@components/Reusables/ButtonFile";
import { useCurrentUser } from "@apollo";
import { FileSpecificType } from "@interfaces";

interface InformationPeopleProps {
  setInformationCreditData: (value: boolean) => void;
}
export const InformationPeople = ({
  setInformationCreditData,
}: InformationPeopleProps) => {
  const classes = useStyles();

  const { saveFile, urlNoFormated } = useContext(ProfileContext);
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { user: currentUser } = useCurrentUser();
  const [form] = useForm();
  useAuth({ redirectIfNotLogged: true });
  const [valueLiquidation, setValueLiquidation] = useState<number>();

  const onDrop = useCallback(
    () => (acceptedFiles: File[]) => {
      saveFile(acceptedFiles[0], "LIQUIDATION", currentUser?.id || 0);
      urlNoFormated?.then((res) => {
        form.liquidationFile = res;
      });
    },
    [currentUser, form]
  );

  const liquidationDropzone = useDropzone({
    accept: "application/pdf",
    onDrop: onDrop(),
  });

  valueLiquidation && Number(valueLiquidation) > 0
    ? setInformationCreditData(true)
    : setInformationCreditData(false);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>2. Información de la persona</div>
        <div className={classes.containerInfo}>
          <div>
            <div>
              <div className={classes.subtitle}>Liquidación de sueldo</div>
              <div className={classes.flex}>
                <div className={classes.grid}>
                  <div className="upload">Ingresar liquidación</div>
                  {isMobile && (
                    <NumericInput
                      className={classes.inputWidth}
                      name={""}
                      onChange={(e) => {
                        form.liquidationAmount = e.target.value as string;
                        setValueLiquidation(Number(e.target.value));
                      }}
                      value={valueLiquidation}
                    />
                  )}
                  <div className="upload">
                    Subir archivo de liquidación (opcional)
                  </div>
                  {isMobile && (
                    <ButtonFile
                      type={FileSpecificType.LIQUIDATION}
                      dropzone={liquidationDropzone}
                    />
                  )}
                </div>
                {!isMobile && (
                  <div className={classes.grid}>
                    <NumericInput
                      className={classes.inputWidth}
                      name={""}
                      onChange={(e) => {
                        form.liquidationAmount =
                          Number(e.target.value) < 1
                            ? undefined
                            : (e.target.value as string);
                        setValueLiquidation(Number(e.target.value));
                      }}
                      value={valueLiquidation}
                    />
                    <ButtonFile
                      type={FileSpecificType.LIQUIDATION}
                      dropzone={liquidationDropzone}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
