import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    fontFamily: "Montserrat",
    width: "37rem",
    height: "auto",
    borderRadius: 30,
    padding: "1.5rem 2rem 0",
    [theme.breakpoints.down(1240)]: {
      width: 330,
      minHeight: "61rem",
      margin: "0 auto",
    },
  },
  textIntroducing: {},
  title: {
    color: "#8352fd",
    fontWeight: "bold",
    fontSize: "1.25rem",
    margin: "0.3rem  0.87rem 0 0",
    fontFamily: "Montserrat",
    marginBottom: "1rem",
    [theme.breakpoints.down(1240)]: {
      margin: 0,
    },
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: "0.5rem 0",
  },
  containerInfo: {
    display: "flex",
    gap: "2rem",
    marginBottom: "2rem",
    [theme.breakpoints.down(1240)]: {
      display: "grid ",
      gap: "0rem",
    },
  },
  creditCategoryInput: {
    width: "16.5rem",
  },
  plazo: {
    width: "5.8rem",
  },
  plazoContainer: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    color: theme.palette.text.secondary,
  },
  percentage: {
    width: "5.8rem",
  },
  porcentageContainer: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    color: theme.palette.text.secondary,
  },
  input: {
    marginBottom: "1rem",
  },
  addOther: {
    marginTop: "3rem",
    [theme.breakpoints.down(1240)]: {
      margin: 0,
    },
    "& .add": {
      margin: "1.5rem 0",
      boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.3)",
      width: "10.47rem",
      height: "2.94rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      gap: "0.5rem",
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  dateInput: {
    "& >div": {
      width: "11.9rem",
    },
  },
}));
