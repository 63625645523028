import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "4rem",
    [theme.breakpoints.down(1240)]: {
      margin: 0,
    },
    "& section": {
      display: "flex",
      justifyContent: "center",
      marginTop: "5rem",
      gap: "5rem",
      alignItems: "start",
      [theme.breakpoints.down(1240)]: {
        display: "grid",
        gap: "2rem",
      },
      "& .div": {
        display: "grid",
        gap: "5rem",
        [theme.breakpoints.down(1240)]: {
          gap: "2rem",
        },
      },
    },
    "& .button": {
      margin: "5rem auto 8rem",
      display: "flex",
      justifyContent: "center",
    },
  },
}));
