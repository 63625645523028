import React from "react";
import { useAuth } from "@hooks";
import { useStyles } from "./RefinanceStep1Top.style";
import { RefinanceCreditImage } from "../../../../images/Refinance/index";
import { useMediaQuery } from "@material-ui/core";

export const RefinanceStep1Top = () => {
  const classes = useStyles();
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1240px)");

  const text = (
    <div className={classes.textIntroducing}>
      Al solicitar un refinanciamiento estás{" "}
      <span>
        contratando un crédito con nuevas condiciones para pagar un préstamo
        antiguo.
      </span>
      Esto puede hacer más cómodos tus pagos ya que podrías acceder a pagos
      pensuales más bajos, una reducción del pedíodo de pago o una tasa de
      interés más baja. Sin embargo, también podía significar que el monto total
      a pagar de tu deuda sea mayor mejor... <br />
      ¿Quieres saber qué alternativas de refinanciamiento existen para tu
      crédito? Completa este formulario para recibir más información.
    </div>
  );
  return (
    <>
      <div className={classes.container}>
        {!isMobile && text}
        <div className={classes.image}>
          <img src={RefinanceCreditImage} />
        </div>
        <div>{isMobile && text}</div>
      </div>
    </>
  );
};
