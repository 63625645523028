import React, { useCallback, useEffect, useState } from "react";
import { useAuth, useForm } from "@hooks";
import { useStyles } from "./InformationCredit.style";
import {
  BirthdateInput,
  CreditCategorySelector,
  TextInput,
} from "@components/Inputs";
import { DateInput, NumericInput } from "@components/Reusables";
import AddIcon from "@material-ui/icons/Add";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isDataView } from "util/types";

interface datCreditProps {
  setCredits: (value: number) => void;
  credits: number;
  number: number;
  allData: any[];
  setInformationCreditData: (value: any) => void;
  enabledButton: boolean;
  valueEnabledButton: (value: boolean) => void;
  setSaveData: React.Dispatch<
    React.SetStateAction<
      {
        numberCredit?: number | undefined;
        category?: string | undefined;
        therm?: number | undefined;
        amount?: number | undefined;
        share?: number | undefined;
        date?: Date | undefined;
        intereseRate?: number | undefined;
      }[]
    >
  >;
  saveData: {
    numberCredit?: number | undefined;
    category?: string | undefined;
    therm?: number | undefined;
    amount?: number | undefined;
    share?: number | undefined;
    date?: Date | undefined;
    intereseRate?: number | undefined;
  }[];
  setDateNow: React.Dispatch<
    React.SetStateAction<
      | {
          numberCredit?: number | undefined;
          category?: string | undefined;
          therm?: number | undefined;
          amount?: number | undefined;
          share?: number | undefined;
          date?: Date | undefined;
          intereseRate?: number | undefined;
        }
      | undefined
    >
  >;
  defaultData?: {
    numberCredit?: number;
    category?: string;
    therm?: number;
    amount?: number;
    share?: number;
    date?: Date;
    intereseRate?: number;
  }[];
}

export const DataCredit = ({
  credits,
  setCredits,
  number,
  allData,
  setSaveData,
  saveData,
  setInformationCreditData,
  valueEnabledButton,
  enabledButton,
  setDateNow,
  defaultData,
}: datCreditProps) => {
  const classes = useStyles();
  useAuth({ redirectIfNotLogged: true });
  const [radioSelected, setRadioSelected] = useState<string>("all");
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelected((event.target as HTMLInputElement).value);
  };
  const [dataCredits, setDataCredits] = useState<{
    numberCredit?: number;
    category?: string;
    therm?: number;
    amount?: number;
    share?: number;
    date?: Date;
    intereseRate?: number;
  }>({
    numberCredit: undefined,
    category: "",
    therm: undefined,
    amount: undefined,
    share: undefined,
    date: undefined,
    intereseRate: undefined,
  });

  const addIncome = useCallback(
    (type, value) => {
      if (type === "category") {
        setDataCredits({
          numberCredit: number,
          category: value,
          therm: dataCredits?.therm,
          amount: dataCredits?.amount,
          share: dataCredits?.share,
          date: dataCredits?.date,
          intereseRate: dataCredits?.intereseRate,
        });
      }
      if (type === "intereseRate") {
        setDataCredits({
          numberCredit: number,
          category: dataCredits?.category,
          therm: dataCredits?.therm,
          amount: dataCredits?.amount,
          share: dataCredits?.share,
          date: dataCredits?.date,
          intereseRate: value,
        });
      }
      if (type === "amount") {
        setDataCredits({
          numberCredit: number,
          category: dataCredits?.category,
          therm: dataCredits?.therm,
          amount: value,
          share: dataCredits?.share,
          date: dataCredits?.date,
          intereseRate: dataCredits?.intereseRate,
        });
      }
      if (type === "date") {
        setDataCredits({
          numberCredit: number,
          category: dataCredits?.category,
          therm: dataCredits?.therm,
          amount: dataCredits?.amount,
          share: dataCredits?.share,
          date: value,
          intereseRate: dataCredits?.intereseRate,
        });
      }
      if (type === "therm") {
        setDataCredits({
          numberCredit: number,
          category: dataCredits?.category,
          therm: value,
          amount: dataCredits?.amount,
          share: dataCredits?.share,
          date: dataCredits?.date,
          intereseRate: dataCredits?.intereseRate,
        });
      }

      if (type === "share") {
        setDataCredits({
          numberCredit: number,
          category: dataCredits?.category,
          therm: dataCredits?.therm,
          amount: dataCredits?.amount,
          share: value,
          date: dataCredits?.date,
          intereseRate: dataCredits?.intereseRate,
        });
      }
      if (
        dataCredits?.category &&
        dataCredits?.therm &&
        dataCredits?.amount &&
        dataCredits?.share &&
        dataCredits?.date &&
        dataCredits?.intereseRate
      ) {
        const newArray: any[] = [];
        const isfound = true;

        valueEnabledButton(true);
        /*  if (saveData.length > 0) {
          saveData.forEach((d) => {
            if (d.numberCredit === dataCredits.numberCredit) {
              isfound = true;
              newArray.push(dataCredits);
            } else {
              newArray.push(d);
            }
          });
          if (!isfound) {
            setSaveData((prev) => {
              return [...prev, dataCredits];
            });
          } else {
            setSaveData(newArray);
          }
        } else {
          setSaveData([dataCredits]);
          valueEnabledButton(false);
        } */
        /* setSaveData((prev) => {
        return [...prev, dataCredits];
      }); */
        // allData[credits] = dataCredits;
        /*       setDateNow((prev) => [...prev, dataCredits]);
         */ //setDateNow(dataCredits);

        // console.log(allData, credits);
      } else {
        valueEnabledButton(false);
      }
    },
    [setDataCredits, dataCredits]
  );

  useEffect(() => {
    console.log("PASOOOO", dataCredits);
    if (
      dataCredits?.category &&
      dataCredits?.therm &&
      dataCredits?.amount &&
      dataCredits?.share &&
      dataCredits?.date &&
      dataCredits?.intereseRate
    ) {
      const newArray: any[] = [];
      console.log("TA LLENO");
      valueEnabledButton(true);
      console.log("enabledButton", enabledButton);
      let isfound = true;
      if (saveData.length > 0) {
        saveData.forEach((d) => {
          if (d.numberCredit === dataCredits.numberCredit) {
            isfound = true;
            newArray.push(dataCredits);
          } else {
            newArray.push(d);
          }
        });
        if (!isfound) {
          setSaveData((prev) => {
            return [...prev, dataCredits];
          });
        } else {
          setSaveData(newArray);
        }
      } else {
        setSaveData([dataCredits]);
        valueEnabledButton(false);
      }
    }
  }, [dataCredits, setDataCredits, valueEnabledButton, enabledButton]);
  if (
    dataCredits?.category &&
    dataCredits?.therm &&
    dataCredits?.amount &&
    dataCredits?.share &&
    dataCredits?.date &&
    dataCredits?.intereseRate
  ) {
    valueEnabledButton(true);
  } else {
    valueEnabledButton(false);
  }
  const createNewIncome = useCallback(async () => {
    setDataCredits({});
  }, [dataCredits]);

  const removeIncome = useCallback(async () => {
    const newArray: any[] = [];
    saveData.forEach((d) => {
      if (d.numberCredit !== credits) {
        newArray.push(d);
      }
    });
    setSaveData(newArray);
  }, []);

  const addNew = useCallback(() => {
    setCredits(credits + 1);
    createNewIncome();
  }, [dataCredits, credits]);

  return (
    <>
      <div className={classes.containerInfo}>
        <div>
          <div>
            <div className={classes.subtitle}>
              Categoría del crédito ({number}){" "}
              {number === credits && number !== 1 && (
                <CloseIcon
                  onClick={() => {
                    setCredits(credits - 1);
                    removeIncome(number);
                  }}
                />
              )}
            </div>

            <CreditCategorySelector
              className={`${classes.creditCategoryInput} ${classes.input}`}
              onChange={({ target: { value } }) =>
                addIncome("category", value as string)
              }
              value={dataCredits?.category || allData[number]?.category}
              disabled={credits !== number}
            />
          </div>
          <div>
            <div className={classes.subtitle}>Monto del crédito</div>
            <NumericInput
              className={classes.input}
              name={"n1"}
              onChange={({ target: { value } }) =>
                addIncome("amount", value as string)
              }
              value={dataCredits?.amount || allData[number]?.amount}
              disabled={credits !== number}
            />
          </div>
          <div>
            <div className={classes.subtitle}>Fecha del primer pago</div>
            <BirthdateInput
              name="date"
              label=" "
              onChange={({ target: { value } }) =>
                addIncome("date", value as string)
              }
              value={dataCredits?.date || allData[number]?.date}
              className={`${classes.input} ${classes.dateInput}`}
              noBirthday
            />
          </div>
          {!isMobile && credits === number && (
            <div className={classes.addOther}>
              <div className={`${classes.subtitle}`}>Tienes otro crédito?</div>
              <div onClick={() => addNew()} className="add">
                <AddIcon />
                Agregar otro
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            <div className={classes.subtitle}>Plazo</div>
            <div className={classes.plazoContainer}>
              <TextInput
                type={"number"}
                className={`${classes.plazo} ${classes.input}`}
                name={"n2"}
                onChange={({ target: { value } }) =>
                  addIncome("therm", value as string)
                }
                value={dataCredits?.therm || allData[number]?.therm}
                disabled={credits !== number}
              />
              <span>Meses</span>
            </div>
          </div>
          <div>
            <div className={classes.subtitle}>Cuota mensual</div>
            <NumericInput
              className={classes.input}
              name={"n3"}
              onChange={({ target: { value } }) =>
                addIncome("share", value as string)
              }
              value={dataCredits?.share || allData[number]?.share}
              disabled={credits !== number}
            />
          </div>
          <div>
            <div className={classes.subtitle}>Interés</div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={radioSelected}
              onChange={handleRadioChange}
              className={`${classes.input}`}
            >
              <FormControlLabel
                value="fraction"
                control={<Radio />}
                label="CAE"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={`Tasa de interés`}
              />
            </RadioGroup>
            <div className={classes.porcentageContainer}>
              <TextInput
                className={classes.percentage}
                type={"number"}
                name={"n4"}
                onChange={({ target: { value } }) =>
                  addIncome("intereseRate", value as string)
                }
                value={
                  dataCredits?.intereseRate || allData[number]?.intereseRate
                }
                /*                 disabled={credits !== number}
                 */
              />{" "}
              %
            </div>
          </div>
        </div>{" "}
        {isMobile && credits === number && (
          <div className={classes.addOther}>
            <div className={`${classes.subtitle}`}>Tienes otro crédito?</div>
            <button disabled={enabledButton}>
              <div onClick={() => addNew()} className="add">
                <AddIcon />
                Agregar otro
              </div>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
