import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    [theme.breakpoints.down(1240)]: {
      display: "grid",
      gridTemplateColumns: "330px",
      gridTemplateRows: "1fr",
      padding: "4rem 0 0",
      background: "#ffffff",
      margin: "0 auto",
      justifyContent: "center",
      borderRadius: 30,
    },
  },
  textIntroducing: {
    width: "45rem",
    color:theme.palette.text.secondary,
    fontWeight: 300,
    fontFamily: "Montserrat",
    paddingTop: "2rem",
    [theme.breakpoints.down(1240)]: {
      width: 330,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  image: {
    width: "24rem",
    [theme.breakpoints.down(1240)]: {
      width: 330,
    },
  },
}));
